// Generated by Framer (d31cd55)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {AMTkoU9z8: {hover: true}};

const serializationHash = "framer-Gtymq"

const variantClassNames = {AMTkoU9z8: "framer-v-1slunf2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "AMTkoU9z8", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 4000, pixelWidth: 6000, positionX: "center", positionY: "top", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg", srcSet: "https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg?scale-down-to=512 512w,https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg?scale-down-to=1024 1024w,https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg?scale-down-to=2048 2048w,https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg?scale-down-to=4096 4096w,https://framerusercontent.com/images/e4pUURfXzuQKFc6kaxthw5pOyMs.jpeg 6000w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1slunf2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AMTkoU9z8"} ref={ref ?? ref1} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} variants={{"AMTkoU9z8-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"AMTkoU9z8-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Gtymq.framer-1yp7dao, .framer-Gtymq .framer-1yp7dao { display: block; }", ".framer-Gtymq.framer-1slunf2 { cursor: pointer; height: 155px; mix-blend-mode: multiply; position: relative; width: 407px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 155
 * @framerIntrinsicWidth 407
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sYaxLzCue":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerp9Fma8V5G: React.ComponentType<Props> = withCSS(Component, css, "framer-Gtymq") as typeof Component;
export default Framerp9Fma8V5G;

Framerp9Fma8V5G.displayName = "image";

Framerp9Fma8V5G.defaultProps = {height: 155, width: 407};

addFonts(Framerp9Fma8V5G, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})